// all scripting that relies on document.ready

(function ($) {
  $(document).ready(function () {

    $('iframe').each(function () { // fix IE youTube iframe embed z-index issue with wmode
      var url = $(this).attr("src");
      var wmode = url.indexOf("wmode");
      var query = url.indexOf("?");

      if (wmode > 0) {
        $(this).attr({
          "wmode": "Opaque"
        });
      }

      else if ((wmode < 1) && query > 0) {
        $(this).attr({
          "src": url + "&wmode=transparent",
          "wmode": "Opaque"
        });
      }

      else {
        $(this).attr({
          "src": url + "?wmode=transparent",
          "wmode": "Opaque"
        });
      }
    });

    var bCheck = checkMsVersion(); // 01_ieCheck.js

    if (bCheck !== -1) {
      $('body').addClass(bCheck);
    }

    /* accessible outline removal --------- */
    $("a, button").mousedown(function () {
      $(this).css("outline", "none");
    });

    $("a, button").keydown(function () {
      $(this).css("outline", "inherit");
    });

    /* nav/table of contents ------------ */

    // Determine which CSS 'transitionend' event is valid in browser (backwards compatibility)
    // (Function from David Walsh: http://davidwalsh.name/css-animation-callback)
    function whichTransitionEvent() {
      var t,
        el = document.createElement("fakeelement");

      var transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
      };

      for (t in transitions) {
        if (el.style[t] !== undefined) {
          return transitions[t];
        }
      }
    }

    var transitionEvent = whichTransitionEvent();

    $(".accrdn--parent").click(function (e) {
      var clickedEl = $(this);

      // if this one's not open and other are, close others first then open this
      if ($(this).hasClass("closed") && $(this).siblings(".open").length) {

        $(this).siblings(".open").removeClass("open").addClass("closed").one(transitionEvent, function () {
          $(clickedEl).addClass("open").removeClass("closed");
        });
      }

      else {// if this one's open, close it; if none are open, just open this
        $(this).toggleClass("open").toggleClass("closed");
      }
    });

    // open TOC: after slides in, set 'main' element class so no scrolling
    // close TOC: remove 'main' element class then slide out

    $(".toc header").click(function (e) {
      e.preventDefault();
      var $toc = $(".toc"),
        $body = $("body");

      if ($toc.hasClass("closed")) {
        // console.log("open menu");
        $toc.toggleClass("open").toggleClass("closed");

        $toc.one(transitionEvent, function () {
          // console.log("toc transition callback");
          $body.addClass("menu--open");
        });
      }

      else {
        $body.removeClass("menu--open");
        $toc.find(".accrdn--parent").removeClass("open").addClass("closed");
        $toc.toggleClass("open").toggleClass("closed");
      }
    });

    $("#edit-search-api-views-fulltext").focus(function () { // add class on search field focus to animate view
      $(".toc").addClass("focus--search");
    });

    $("#edit-search-api-views-fulltext").blur(function () { // add class on search field focus to animate view
      $(".toc").removeClass("focus--search");
    });


    /* modals ---------------- */

    // object image modals
    $(".overlay-launch").click(function (e) {
      var $staticImgOverlay = $(".field-name-field-overlay-image");
      if ($staticImgOverlay.length > 0) {
        var winHeight = window.innerHeight;
        //console.log("this is a static image overlay. winHeight=" + winHeight);
        $(".overlay__container--inner").css("height", winHeight - 80 + "px");
        $staticImgOverlay.css("height", winHeight - 140 + "px");
        $staticImgOverlay.find("img").css("height", "100%");
        $staticImgOverlay.find("img").css("width", "auto");
      }
      $(".overlay").addClass("visible");
      $("main, body").addClass("modal");
    });

    $(".overlay .element__close").click(function (e) {
      $(".overlay").removeClass("visible");
      $("main, body").removeClass("modal");
    });


    // figure modals

    $("#main-content .field-name-field-inline-figure figure, #main-content .field-name-field-figure-references figure").click(function () {
      $(this).parent().addClass("overlay").addClass("visible");
      $("main, body").addClass("modal");
    });

    $("#main-content .field-name-field-inline-figure ").on("click", ".element__close", function () {
      $(this).parents(".field-name-field-inline-figure").removeClass("visible").removeClass("overlay");
      $("main, body").removeClass("modal");
    });

    $("#main-content .field-name-field-figure-references ").on("click", ".element__close", function () {
      $(this).parents(".field-name-field-figure-references").removeClass("visible").removeClass("overlay");
      $("main, body").removeClass("modal");
    });

    /* footnotes -------------- */

    $(".note-link").click(function (e) {
      e.preventDefault();
      var noteHref = this.href;
      var noteHrefArr = noteHref.split("/");
      var nodeNum = noteHrefArr.slice(-1);

      var noteId = "note-" + nodeNum;
      var $noteObj = $("#" + noteId);

      if ($noteObj.hasClass("visible")) {  // if open note reference is clicked on, close it
        $("body").removeClass("note--visible");
        $noteObj.removeClass("visible");
      }

      else {
        //console.log("open a new note");
        var $visibleNote = $(".visible");

        if ($visibleNote.length > 0) { // if a different note is open, close it then open new one
          $($visibleNote).removeClass("visible");
          $($visibleNote).one(transitionEvent, function () {
            $noteObj.addClass("visible");
          });
        }

        else {
          $noteObj.addClass("visible");
          $("body").addClass("note--visible");
        }
      }
    });

    $("[id^='note-']").find(".element__close").click(function (e) {
      e.preventDefault();
      $(this).parents("[id^='note-']").removeClass("visible");
      $("body").removeClass("note--visible");
    });

    /* transcripts --------------- */
    $(".field-name-field-video").find("h3.field-label").click(function (e) {
      var $transcriptElem = $(".field-name-field-transcript");

      if ($transcriptElem.hasClass("visible")) {
        $(this).removeClass("visible");
        $transcriptElem.removeClass("visible");
      }

      else {
        $(this).addClass("visible");
        $transcriptElem.addClass("visible");
      }
    });

    /* help button/text area --------- */
    $(".help").click(function (e) {
      e.preventDefault();
      $(this).toggleClass("visible");
      $(this).siblings(".help-text").toggleClass("visible");
    });

    $(".technq__def__toggle").click(function (e) {
      e.preventDefault();
      $(this).toggleClass("visible");
      $(this).next(".technq__definition").toggleClass("visible");
    });

    /* decorative techniques accordion list ------- */

    $(".button__text--collapse").hide();

    $(".technq__accordion--control button").click(function () {

      if ($(this).hasClass("active")) { // all categories already expanded by button
        $(".lvl--0 > li, .lvl--1 > li").removeClass("accrdn--open");
        $(".lvl--0 > li").removeClass("accrdn--child-open");

        $(this).children(".button__text--collapse").fadeOut(500, function () {
          $(".button__text--expand").fadeIn(500);
        });
      }

      else {
        $(".lvl--0 > li, .lvl--1 > li").addClass("accrdn--open");
        $(".lvl--0 > li").addClass("accrdn--child-open");

        $(this).children(".button__text--expand").fadeOut(500, function () {
          $(".button__text--collapse").fadeIn(500);
        });
      }

      $(this).toggleClass("active");
    });

    $(".lvl--0 > li > header > .technq__expand").click(function () { // top level accordion click
      var $lvl0listItem = $(this).closest("li");

      if ($lvl0listItem.hasClass("accrdn--child-open")) { // children open? --> collapse
        $lvl0listItem.removeClass("accrdn--child-open");
        $lvl0listItem.find(".lvl--1 > li").removeClass("accrdn--open");
      }

      $lvl0listItem.toggleClass("accrdn--open"); // always toggle this level 'open' class

    });

    $(".lvl--1 > li > header > .technq__expand").click(function () { // second level accordion click
      var $lvl1listItem = $(this).closest("li");

      // current state: opened or closed?
      if ($lvl1listItem.hasClass("accrdn--open")) {// currently opened --> close

        if (!($lvl1listItem.siblings(".accrdn--open").length)) { // if we're closing the only open second level item, remove open class on parent too
          $lvl1listItem.closest(".lvl--0 > li").removeClass("accrdn--child-open");
        }
      }

      else { // currently closed --> open  (expand parent)
        $lvl1listItem.closest(".lvl--0 > li").addClass("accrdn--child-open");
      }

      $lvl1listItem.toggleClass("accrdn--open"); // always toggle this level 'open' class

    });


    $(".anchor-link").click(function (e) {// mimic page anchor link with element id
      e.preventDefault();
      var anchorId = $(this).attr("href");
      var targetedElem = $("" + anchorId);
      //console.log("test=" + anchorId);

      if (targetedElem) {
        //console.log("targetedElem exists");
        var scrollPos = targetedElem.offset();
        //console.dir(scrollPos);
        scrollTopStr = (scrollPos.top - 130) + "px";
        //console.log(scrollTopStr);
        $("html, body").animate({scrollTop: scrollTopStr});
      }
    });

    if ($(".page-node-313").length > 0) { // add pagination to page title for video index
      var url = window.location.search,
        pageStr = "",
        headerText = $(".article-header h1").text();

      var urlArr = url.split("page=");
      var pageNum = parseInt(urlArr[1]) + 1;

      if (pageNum) {
        pageStr = pageNum.toString();
      }

      if (pageStr.length > 0) {
        $(".article-header h1").text(headerText + " (page" + pageStr + ")");
      }
    }
  });
}(jQuery));

// running panzoom with later (recommended) version of jQuery via jqmulti module
(function ($) {
  $(document).ready(function () {
    var $pzContainer = $(".object__image-full").find("figure");
    // console.log("jquery:" +  $.fn.jquery);
    // console.dir($pzContainer);

    if ($pzContainer.length) {
      // console.log("$pzContainer exists");
      $pzContainer.panzoom({
        minScale: 1,
        maxScale: 10,
        increment: 0.5,
        exponential: false,
        $zoomIn: $(".zoom--plus"),
        $zoomOut: $(".zoom--minus")
      });

      $pzContainer.panzoom("zoom", 1.5);

      $(window).on("orientationchange", function (e) {
        // console.log("orientationchange");
        $pzContainer.panzoom("resetDimensions");
        $pzContainer.panzoom("reset");
      });
    }
  });


})(jq310);