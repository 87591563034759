function checkMsVersion() {
  var rv = -1; // Return value assumes failure.
  var ua = navigator.userAgent;

  //console.log(navigator.appName);

  if (navigator.appName == 'Microsoft Internet Explorer') {

    re = new RegExp('MSIE ([0-9]{1,}[\\.0-9]{0,})');

    if (re.exec(ua) !== null) {
      rv = 'ie' + parseFloat(RegExp.$1);
    }
  }
  else if (navigator.appName == 'Netscape') {
    /// in IE 11 the navigator.appVersion says 'trident'
    /// in Edge the navigator.appVersion does not say trident

    if (navigator.appVersion.indexOf('Trident') > 0) {
      rv = 'ie11';
    }

    else if (ua.indexOf('Edge') > 0) {
      rv = 'edge';
    }
  }

  return rv;
}